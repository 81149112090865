<template>
  <div class="wrapper wrapper_login">
    <div class="flex flex-center">
      <div>
        <div class="text-center">
          <h1 class="mb-8">Brave manager</h1>

          <p class="text-grey-dark mb-4 mx-auto">
            Авторизуйтесь, используя<br />
            корпоративную почту @aic.ru
          </p>

          <BmButton
            class="btn_md"
            label="Войти через Google"
            outlined
            rounded
            @click.native="login"
          >
            <template #icon>
              <BmIconGoogle class="mr-3" />
            </template>
          </BmButton>
        </div>

        <transition name="fade">
          <div
            v-if="showSuccessMessage"
            class="response-message response-message_success mt-8"
          >
            <BmIconCheck class="response-message__icon" />
            <p>
              Сообщение отправлено! Ожидайте ответ на него по указанной вами
              почте.
            </p>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="showErrorMessage"
            class="response-message response-message_error mt-8"
          >
            <BmIconAlert class="response-message__icon" />
            <p>
              У этой почты нет доступа к Brave Manager. Повторите вход через
              корпоративную почту @aic.ru или напишите администратору.
            </p>
          </div>
        </transition>
      </div>
    </div>

    <PageFooter @open:requestAccessModal="requestAccessDialog = true" />

    <BmModal :open.sync="requestAccessDialog" class="modal_md">
      <RequestAccessForm
        :loading="requestAccessFormSubmitting"
        @submit="onSubmitRequestAccessForm"
      />
    </BmModal>
  </div>
</template>
<script>
import api from '@/api'
import PageFooter from '../components/Footer/PageFooter'
import RequestAccessForm from '../components/Footer/_components/RequestAccessForm'

export default {
  name: 'LoginPage',

  components: {
    PageFooter,
    RequestAccessForm,
  },

  data() {
    return {
      showSuccessMessage: false,
      showErrorMessage: false,

      requestAccessDialog: false,
      requestAccessFormSubmitting: false,
    }
  },

  methods: {
    getToken() {
      const matches = window.location.search.match('[?&]token=([^&]+)')
      return matches?.length === 2 ? decodeURIComponent(matches[1]) : null
    },

    login() {
      window.location = process.env.VUE_APP_AUTH_URL
    },

    async onSubmitRequestAccessForm(data) {
      try {
        this.requestAccessFormSubmitting = true
        await api.notifications.requestAccess(data)

        this.showSuccessMessage = true
        this.requestAccessDialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.requestAccessFormSubmitting = false
      }
    },
  },

  watch: {
    requestAccessFormSubmitting() {
      if (this.requestAccessFormSubmitting) {
        this.showSuccessMessage = false
      }
    },
  },

  mounted() {
    const token = this.getToken()

    if (token) {
      localStorage.setItem('token', token)
    }

    if (localStorage.getItem('token')) {
      this.$router.push({name: 'ProjectsPage'})
    }
  },
}
</script>
