import {validateEmail} from '@src/utils/helpers'

export const valid = () => true

export const require = () => {
  return (val) => {
    if (Array.isArray(val)) {
      return val.length || 'Заполните поле.'
    }

    return !!val || 'Заполните поле.'
  }
}

export const email = () => {
  return (val) => validateEmail(val) || 'Некорректный адрес электронной почты.'
}
