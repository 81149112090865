export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function mockResponse(payload, ms = 500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          payload,
        },
      })
    }, ms)
  })
}

export function arcctg(x, y) {
  if (x > 0 && y > 0) {
    return Math.PI / 2 - Math.atan(x / y)
  }
  if (x < 0 && y > 0) {
    return Math.PI / 2 - Math.atan(x / y)
  }
  if (x < 0 && y < 0) {
    return Math.PI + Math.atan(y / x)
  }
  if (x > 0 && y < 0) {
    return (3 * Math.PI) / 2 + Math.abs(Math.atan(x / y))
  }
}

export function numberWithSpaces(number) {
  return number.toLocaleString()
}

export function initials(name) {
  return name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('')
}

export function getDay(date) {
  let day = date.getDate()

  if (day < 10) {
    day = '0' + day
  }

  return day.toString()
}

export function getMonth(date) {
  let month = date.getMonth() + 1

  if (month < 10) {
    month = '0' + month
  }

  return month.toString()
}

export function daysRange(date) {
  const range = this.$moment().range(
    this.$moment(date, 'YYYY-MM-DD').weekday(0),
    this.$moment(date, 'YYYY-MM-DD').weekday(6)
  )

  return Array.from(range.by('day'))
}

export function weeksRange(date) {
  const range = this.$moment().range(
    this.$moment(date, 'YYYY-MM-DD').weekday(0).subtract(3, 'week'),
    this.$moment(date, 'YYYY-MM-DD').weekday(0).add(3, 'week')
  )

  return Array.from(range.by('week'))
}

export function weekLabel(date, withoutMonth) {
  const startOfWeek = this.$moment(date, 'YYYY-MM-DD').weekday(0)
  const endOfWeek = startOfWeek.clone().add(6, 'days')

  if (withoutMonth) {
    return startOfWeek.format('DD') + ' - ' + endOfWeek.format('DD')
  }

  return startOfWeek.format('DD.MM') + '-' + endOfWeek.format('DD.MM')
}

export function getWeeks() {
  let result = []
  const firstWeek = this.$moment().weekday(0).subtract(10, 'week')
  for (let i = 0; i < 20; i++) {
    const date = firstWeek.clone().add(i, 'weeks').format('YYYY-MM-DD')
    result.push({
      id: date,
      name: this.weekLabel(date),
    })
  }
  return result
}
