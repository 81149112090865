<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form__title mt-11 text-center">Запросить доступ</div>

    <div class="form__subtitle text-center">
      Для доступа к сервису Brave Manager,<br />
      напишите администратору.
    </div>

    <div class="mb-6">
      <BmInput
        v-model="form.email"
        outlined
        label="E-mail"
        :rules="emailValidators"
        ref="email"
      />
    </div>

    <div class="mb-6">
      <BmInput
        label="Сообщение"
        outlined
        v-model="form.message"
        type="textarea"
      />
    </div>

    <div class="mt-6">
      <BmButton
        size="md"
        rounded
        type="submit"
        label="Отправить сообщение"
        class="full-width"
        :loading="loading"
      />
    </div>
  </form>
</template>

<script>
import {email} from '@src/utils/validators'

export default {
  name: 'RequestAccessForm',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    submit: null,
  },
  data() {
    return {
      form: {
        email: null,
        message: null,
      },

      emailValidators: [email()],
    }
  },
  methods: {
    onSubmit() {
      const isValid = this.$refs.email.validate()

      if (!isValid) {
        return
      }

      this.$emit('submit', this.form)
    },
  },
}
</script>
