<template>
  <footer class="footer">
    Написать
    <a
      class="text-blue"
      href="/request-access"
      @click.prevent="openRequestAccessModal"
    >
      администратору
    </a>
    для получения доступа к сервису
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  methods: {
    openRequestAccessModal() {
      this.$emit('open:requestAccessModal')
    },
  },
}
</script>
<style src="@src/scss/footer.scss" lang="scss"></style>
